<script>
import {useHead} from "@vueuse/head";
import ogImage from "@/assets/images/fb.jpg";

export default {
    name: "page-404",
    data() {
      return {
          title: "404",
          slug: this.$route.params.slug
      }
    },
    mounted() {
        document.body.classList.add('notfound');
        document.documentElement.classList.remove('menu-active');
        document.documentElement.classList.remove('content-active');

        useHead({
            htmlAttrs: {
                lang: 'en',
            },
            title: `${this.title} - Miroslav Bešlin`,
            meta: [
                { name: 'description', content: `${process.env.VUE_APP_SITE_DESCRIPTION}` },
                { property: 'og:title', content: `${this.title} - ${process.env.VUE_APP_SITE_TITLE}` },
                { property: 'og:site_name', content: `${process.env.VUE_APP_SITE_TITLE} - ${process.env.VUE_APP_SITE_TAGLINE}` },
                { property: 'og:type', content: 'website' },
                { property: 'og:url', content: `${process.env.VUE_APP_SITE_URL}/404` },
                { property: 'og:description', content: `${process.env.VUE_APP_SITE_DESCRIPTION}` },
                { property: 'og:image', content: ogImage }
            ],
        });
    }
}
</script>

<template>
    <div class="page-content position-absolute">
        <h1 class="color-light m-0 mb-10">Seems you're lost</h1>
        <p class="color-light m-0">404 - Not found</p>
    </div>
</template>

<style scoped lang="scss">

</style>
