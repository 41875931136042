<script>
import {useHead} from "@vueuse/head";
import ogImage from '@/assets/images/fb.jpg';

export default {
    name: "page-main",
    data() {
        return {
            title: `${process.env.VUE_APP_SITE_TITLE} - ${process.env.VUE_APP_SITE_TAGLINE}`,
        }
    },
    methods: {
        setHeadTags() {
            useHead({
                htmlAttrs: {
                    lang: 'en',
                },
                title: this.title,
                meta: [
                    { name: 'description', content: `${process.env.VUE_APP_SITE_DESCRIPTION}` },
                    { property: 'og:title', content: this.title },
                    { property: 'og:site_name', content: this.title },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:url', content: `${process.env.VUE_APP_SITE_URL}` },
                    { property: 'og:description', content: `${process.env.VUE_APP_SITE_DESCRIPTION}` },
                    { property: 'og:image', content: ogImage }
                ],
            });
        }
    },
    created() {
        this.setHeadTags()
    },
    watch: {
        '$route.params.slug': {
            immediate: true,
            handler() {
                this.setHeadTags()
            },
        },
    },
}
</script>

<template>
    <section class="main">
        <img src="@/assets/images/beslin-logo.svg" alt="Miroslav Bešlin Logo" class="main-logo" width="400" height="229"/>
    </section>
</template>

<style scoped lang="scss">

</style>
